// models and their bounds
const models = {
  HRDPS: {
    bounds: [-150, 38, -120, 68],
  },
  RDWPS: {
    bounds: [-150, 38, -120, 68],
  },
  'CIOPS-SalishSea': {
    bounds: [-126.2, 47, -121.11319, 51],
  },
  'CIOPS-West': {
    bounds: [-140, 44.3, -122, 60],
  },
  // this is used in restricting queries only
  CIOPS: {
    bounds: [-140, 44.3, -121.11319, 60],
  },
};

export default models;
