import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Fallback from './Fallback.jsx';
import 'large-small-dynamic-viewport-units-polyfill';
import { isMobileApp } from './utilities.js';
import App from './App.jsx';

// don't let users install PWA from the browser
if ('serviceWorker' in navigator && isMobileApp()) {
  console.log('SW supported');
  window.addEventListener('load', () => {
    console.log('SW registered');
    navigator.serviceWorker
      .register('/serviceWorker.js')
      .then((registration) => {
        console.log('SW registered: ', registration);
      })
      .catch((registrationError) => {
        console.log('SW registration failed: ', registrationError);
      });
  });
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: process.env.SENTRY_ENVIRONMENT || 'dev',
    dsn: 'https://8f7aa828928941b8804e02d33d93b8fa@o56764.ingest.sentry.io/4504290508931072',
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Sentry.ErrorBoundary fallback={<Fallback open={true} />}>
    <App />
  </Sentry.ErrorBoundary>
);
